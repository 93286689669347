import React from 'react'
import { Link } from 'gatsby'

import SEO from '../../components/SEO'
import withContext from '../../lib/withContext'

const metaData = {
  title: 'Thank you!',
  image: undefined
}

const ThanksPage = () => (
  <div>
    <SEO metaData={metaData} />
    <main className="thanks">
      <header className="header padding-xxxl">
        <div className="container-md">
          <div className="grid yg center text-center">
            <div className="col-12">
              <h1>Thank you!</h1>
              <p className="streamer center margin-s-bottom">Thank you for subscribing. You will receive updates when new icon sets have been added.</p>
              <Link to="/" className="btn primary lg">
                Return to Homepage
              </Link>
            </div>
          </div>
        </div>
      </header>
    </main>
  </div>
)

export default withContext(ThanksPage)
